import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { H4, HR, Link } from '@system'
import useStringTranslation from '@hooks/use-string-translation'
import font from '@helpers/font'
import getFormattedDateTime from '@helpers/get-formatted-date-time'
const { makeResponsiveFontSizes } = font

const useStyles = makeStyles((theme) => ({
  calendarEventRoot: {
    width: '100%',
  },
  content: {
    padding: '3rem 0',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 0',
      alignItems: 'flex-start',
    },
  },
  dateBox: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: '108px',
    width: '108px',
    minHeight: '108px',
    minWidth: '108px',
    ...makeResponsiveFontSizes(24, 15),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    wordSpacing: '9999px', // forces line break between MMM / DD
    textAlign: 'center',
    lineHeight: '1.2',
  },
  textItemsBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '-webkit-fill-available',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: '1.5rem',
    },
  },
  eventTitle: {
    paddingLeft: '2rem',
    maxWidth: '50%',
    fontWeight: theme.typography.fontWeightBold,
    ...makeResponsiveFontSizes(28, 15),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0',
      maxWidth: '100%',
    },
  },
  locationLinkContainer: {
    width: '350px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: 'unset',
    },
  },
  locationBox: {
    padding: '1rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '200px',
  },
  locationLine1: {
    color: theme.palette.text.tertiary,
    ...makeResponsiveFontSizes(24, 15),
  },
  locationLine2: {
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightBold,
    ...makeResponsiveFontSizes(24, 15),
  },
  eventLinkContainer: {
    width: '135px',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  eventLink: {
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    ...makeResponsiveFontSizes(28, 15),
  },
}))

const CalendarEvent = (props) => {
  const classes = useStyles(props)
  const {
    eventTitle,
    startDate,
    endDate,
    locationLine1,
    locationLine2,
    eventLink,
  } = props.blok

  const formatEventDate = () => {
    const formattedStartDate = startDate
      ? getFormattedDateTime(startDate, 'MMM D', false).toUpperCase()
      : 'FEB 30'
    const formattedEndDate = endDate
      ? `-${getFormattedDateTime(endDate, 'D', false)}`
      : ''
    return formattedStartDate + formattedEndDate
  }

  const hasEventLink = !!eventLink?.url || !!eventLink?.story?.url
  const translatedLinkText = useStringTranslation('READ MORE')

  return (
    <SbEditable content={props.blok}>
      <Box className={classes.calendarEventRoot}>
        <Box className={classes.content}>
          <Box className={classes.dateBox}>{formatEventDate()}</Box>
          <Box className={classes.textItemsBox}>
            <H4 className={classes.eventTitle}>{eventTitle}</H4>
            <Box className={classes.locationLinkContainer}>
              <Box className={classes.locationBox}>
                <H4 className={classes.locationLine1}>{locationLine1}</H4>
                <H4 className={classes.locationLine2}>{locationLine2}</H4>
              </Box>
              <Box className={classes.eventLinkContainer}>
                {hasEventLink && (
                  <Link link={eventLink} linkStyle={classes.eventLink}>
                    {translatedLinkText}
                  </Link>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        {props.shouldRenderHR && (
          <HR color={{ color: '#000' }} widthProportion={100} lineHeight={1} />
        )}
      </Box>
    </SbEditable>
  )
}

export default CalendarEvent
